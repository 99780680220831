import { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useUserStore } from 'shared/stores/useUserStore'
import { useAcl } from 'ui/hooks'
import { ReactChildren } from 'ui/types'
import { useInitStore } from '../stores/useInitStore'
import Api from 'ui/libs/api'

type PrivateRouteProps = {
    children: ReactChildren
    onLogin?: (token: string) => void
    initService: () => void
    onTokenUpdate: (token: string) => void
}

function PrivateRoute<Rights>({
    children,
    onLogin,
    initService,
    onTokenUpdate
}: PrivateRouteProps) {
    const { keycloak } = useKeycloak()
    const [render, setRender] = useState(false)
    const acl = useAcl<Rights>()

    const [
        setLogin,
        setToken,
        setKeycloak,
        apply401
    ] = useUserStore(state => [
        state.setLogin,
        state.setToken,
        state.setKeycloak,
        state.apply401
    ])

    const [
        loaded
    ] = useInitStore(state => [
        state.loaded
    ])

    useEffect(() => {
        setKeycloak(keycloak)
    }, [])

    useEffect(() => {
        if (keycloak?.tokenParsed) {
            setLogin({
                id: keycloak?.tokenParsed?.sub,
                name: keycloak?.tokenParsed?.name,
                firstname: keycloak?.tokenParsed?.given_name,
                lastname: keycloak?.tokenParsed?.family_name,
                username: keycloak?.tokenParsed?.preferred_username,
                email: keycloak?.tokenParsed?.email,
                token: keycloak.token,
                acl: null, //getAcl(keycloak?.tokenParsed.realm_access?.roles),
                roles: keycloak?.tokenParsed.realm_access?.roles
            })

            if(keycloak?.tokenParsed.realm_access?.roles) {
                acl.assignRoles(keycloak?.tokenParsed.realm_access?.roles)
            }

            /*
            keycloak.onAuthRefreshSuccess = keycloak.onAuthRefreshError = () => {
                console.log('REFRESH TOKEN', keycloak.token)
                setToken(keycloak.token)
                onTokenUpdate(keycloak.token)
            }
            */

            keycloak.onTokenExpired = () => {
                console.log('TOKEN EXPIRED', keycloak.token)
                keycloak
                    .updateToken(5)
                    .then(() => {
                        console.log('successfully get a new token', keycloak.token)
                        setToken(keycloak.token)
                        onTokenUpdate(keycloak.token)
                    })
                    .catch((err) => {
                        keycloak.login()
                    })
            }

            if(!loaded) {
                initService()
                if(onLogin) onLogin(keycloak.token)
                Api.setOnUnauthorized(() => {
                    apply401()
                })
            }
            setKeycloak(keycloak)
            setRender(true)
        }
    }, [keycloak?.tokenParsed, keycloak.token])

    return render ? children : null
}

export default PrivateRoute