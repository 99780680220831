import React, { createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactChildren } from '../types'

export enum RightsBaseEnum {
    All = "all",
    None = "none",
}

type AclTree<R> = Record<string, R[]>

export class Acl<R extends RightsBaseEnum> {

    private roles: AclTree<R> = {}
    private clientRoles: string[] = []
    private rights: (R | RightsBaseEnum)[] = []

    constructor(roles?: AclTree<R>) {
        if(roles) {
            this.roles = roles
        }
    }

    noRights() {
        return this.rights.includes(RightsBaseEnum.None)
    }

    allRights() {
        return this.rights.includes(RightsBaseEnum.All)
    }

    isAllows(actions: R[]): boolean {
        let allow = false
        actions.forEach(right => {
            if(this.isAllow(right)) allow = true
        })

        return allow
    }

    isDenies(actions: R[]): boolean {
        return !this.isAllows(actions)
    }

    isAllow(action: R): boolean {
        return this.rights.includes(action) || this.allRights()
    }
    isDeny(action: R): boolean {
        return !this.isAllow(action)
    }

    haveRole(role: string): boolean {
        return this.clientRoles.includes(role)
    }

    assignRoles(roles: string[]): void {
        const keys = Object.keys(this.roles)
        this.clientRoles = roles.filter(role => keys.includes(role))
        //this.clientRoles = ['validator']
        //this.clientRoles = ['advisor']

        this.clientRoles.forEach(role => {
            if(this.roles[role]) {
                this.roles[role].forEach(r => {
                    if(!this.rights.includes(r)) {
                        this.rights.push(r)
                    }
                })
            }
        })

        if(this.rights.length > 1) {
            const index = this.rights.indexOf(RightsBaseEnum.None)
            if(index !== -1) {
                this.rights.splice(index, 1)
            }
        }
    }

    getRoles(): string[] {
        return this.clientRoles
    }
}


const AclContext = createContext(new Acl<unknown>())

interface AclProviderProps<R> {
    acl: AclTree<R>
    children: ReactChildren
}

export function AclProvider<R extends AclTree<R>>({
    acl,
    children
}: AclProviderProps<R>) {

    const value = new Acl<R>(acl)
    return (
        <AclContext.Provider value={value}>
            {children}
        </AclContext.Provider>
    )

}

export function useAcl<R extends AclTree<R>>()  {
    return useContext<Acl<R>>(AclContext)
}
