import { RightsBaseEnum } from "ui"

enum RightsEnum {
    HeathInsuranceView = 'heathInsurance.view',
    HeathInsuranceNew = 'heathInsurance.new',
    LifeInsuranceView = 'lifeInsurance.view',
    LifeInsuranceNew = 'hlifeInsurance.new',
    LppView = 'Lpp.view',
    LppNew = 'Lpp.new',
    WeekvestView = 'Weekvest.view',
    WeekvestNew = 'Weekvest.new',
    ClientView = 'client.view',
    ClientNew = 'client.new',
    CanChangeMessageToCompany = 'contract.lifeInsurance.canChangeMessageToCompany',
    BuilderView = 'BuilderView'
}
export const Rights = { ...RightsBaseEnum, ...RightsEnum }

export const acl = {
    "admin": [
        Rights.All
    ],
    "manager": [
        Rights.HeathInsuranceView,
        Rights.HeathInsuranceNew,
        Rights.BuilderView,
    ],
    "quality": [
        Rights.None
    ],
    "validator": [
        Rights.None,
    ],
    "advisor": [
        Rights.HeathInsuranceView,
        Rights.HeathInsuranceNew,
        Rights.BuilderView
    ]
}

export default acl